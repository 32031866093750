import { Message } from 'element-ui'

export function uploadAccept(file, type) {
  const fileName = file.name || ''
  const ext = fileName.split('.')[fileName.split('.').length - 1].toLowerCase()
  let flag = true
  if (type === '视频') {
    if (ext !== 'mp4' && ext !== 'mov') {
      Message.error('视频格式不对！！！')
      flag = false
    }
  } else if (type === '音频') {
    if (ext !== 'mp3') {
      Message.error('音频格式不对！！！')
      flag = false
    }
  } else if (type === '文档') {
    if (ext !== 'doc' &&
      ext !== 'docx' &&
      ext !== 'xls' &&
      ext !== 'xlsx' &&
      ext !== 'ppt' &&
      ext !== 'pptx' &&
      ext !== 'pdf') {
      Message.error('文档格式不对！！！')
      flag = false
    }
  } else if (type === '图片') {
    if (ext !== 'jpg' && ext !== 'png') {
      Message.error('图片格式不对！！！')
      flag = false
    }
  } else if (type === '导入人员') {
    if (ext !== 'xls' &&
      ext !== 'xlsx') {
      Message.error('文档格式不对！！！')
      flag = false
    }
  } else if (type === 'html') {
    if (ext !== 'html') {
      Message.error('html格式不对！！！')
      flag = false
    }
  }
  return flag
}
