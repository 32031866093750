<template>
	<el-dialog :visible.sync="show" width="50%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="110px" class="allForm" :disabled="info.addOr === 'view'">
					<el-form-item label="新闻标题" :required="true">
						<el-input v-model="form.newsTitle" class="formInput" />
					</el-form-item>
					<el-form-item label="选择公司" :required="$admin?false:true">
						<!-- <el-select
              v-model="form.companyIds"
              class="formInput w-100"
              filterable
              multiple
            >
              <el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
            </el-select> -->
						<template>
							<el-transfer filterable filter-placeholder="请输入公司名称" v-model="form.companyIds" :titles="['全选','全选']"
							 target-order="unshift" :props="{
				      key: 'id',
				      label: 'companyName'
				  }" :data="company">
							</el-transfer>
						</template>
					</el-form-item>
					<el-form-item label="新闻内容" :required="true" class="formInput">
						<wang :content-back="form.newsContentHtml" :disabled="info.addOr === 'view'" @get-content="getContent" />
					</el-form-item>
					<el-form-item label="新闻封面" :required="true">
						<el-upload ref="upload" class="upload-demo w-100" drag :headers="{
                Authorization: $token
              }"
						 :action="this.$srcUrl+upFile" list-type="picture" :file-list="fileList" :auto-upload="true" :limit="1"
						 :on-success="success" :on-change="change" :on-exceed="overFile">
							<i class="el-icon-receiving" style="font-size: 80px;" />
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>，只支持传一张图片</div>
						</el-upload>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div v-if="info.addOr!=='view'" slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import wang from '@/components/WangEditor/index'
	import {
		journalism,
		upFile,
		company
	} from '@/api'
	import {
		downFile
	} from '@/utils/file'
	import {
		uploadAccept
	} from '@/utils/uploadAccept/index'
	import {
		axiosReqCross
	} from '@/utils/request'
	export default {
		name: 'Operate',
		components: {
			wang
		},
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					newsTitle: '',
					newsContent: '',
					// newsContentUrl: '',
					newsCoverUrl: '',
					companyIds: this.$admin ? [] : [this.$companyId],
					newsContentHtml: '',
				},
				fileList: [],
				fileList2: [],
				file: '',
				company: [],
				upFile
			}
		},
		created() {
			// this.getContentUpd()
			this.getCompany()
		},
		mounted() {
			this.$nextTick(() => {
				this.upd()
			})
		},
		methods: {
			upd() {
				if (this.info.addOr !== 'add') {
					this.$axiosReq(journalism + this.row.id, null, null, 'get').then(res => {
						this.form.newsTitle = res.data.newsTitle
						this.form.newsContent = res.data.newsContent
						this.form.newsContentUrl = res.data.newsContentUrl
						this.form.newsContentHtml = res.data.newsContentHtml
						this.form.newsCoverUrl = res.data.newsCoverUrl
						for (const i of res.data.companys) {
							this.form.companyIds.push(i.id)
						}
						this.form.companyIds = Array.from(new Set(this.form.companyIds));
						this.fileList.push({
							url: this.$fileUrl + this.form.newsCoverUrl,
							name: '封面'
						})
						// this.fileList2.push({ url: this.$fileUrl + this.form.newsContentUrl, name: '文件' })
						// this.getContentUpd()
					})
				}
			},
			change(file, fileList) {
				const fileSize = file.size / 1024 / 1024 < 500
				if (!fileSize) {
					this.$message.error('文件大小不能超过500MB！！！')
					return false
				}
				let flag = false
				flag = uploadAccept(file, '图片')
				if (flag) {
					this.file = file
				} else {
					fileList.splice(-1, 1)
				}
			},
			overFile() {
				this.$message.error('只能传一个！')
			},
			success(response) {
				this.form.newsCoverUrl = response.data.filePath
			},
			change2(file, fileList) {
				const fileSize = file.size / 1024 / 1024 < 500
				if (!fileSize) {
					this.$message.error('文件大小不能超过500MB！！！')
					return false
				}
				let flag = false
				flag = uploadAccept(file, 'html')
				if (flag) {
					this.file = file
				} else {
					fileList.splice(-1, 1)
				}
			},
			async getContentUpd() {
				if (this.form.newsContentUrl) {
					await axiosReqCross(this.form.newsContentUrl, null, null, 'get').then(res => {
						this.content = res
					})
				}
			},
			getContent(v) {
				this.form.newsContentHtml = v.content
				this.form.newsContent = v.noHtml
			},
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content
				})
			},
			// 提交添加
			commit() {
				if (this.$textNull(this.form.newsTitle)) {
					this.$message.error('请填写新闻标题！')
					return
				} else if (this.$textNull(this.form.newsContentHtml)) {
					this.$message.error('请上传新闻内容文件！')
					return
				} else if (this.$textNull(this.form.newsCoverUrl)) {
					this.$message.error('请选择新闻封面！')
					return
				} else if (!this.$admin) {
					if (this.form.companyIds.length === 0) {
						this.$message.error('请选择公司！')
						return
					}
				}
				let src = this.form.newsContentHtml.substring(0,5)
				if (src != '<meta') {
					this.form.newsContentHtml =
						'<html><meta charset="utf-8"><meta name="viewport" content="maximum-scale=1.0,minimum-scale=1.0,user-scalable=0,width=device-width,initial-scale=1.0" /><body style="font-size:14pt">' +
						this.form.newsContentHtml + '</body></html>';
				} else {
					this.form.newsContentHtml = '<html>' + this.form.newsContentHtml + '</html>';
				}
				const loading = this.$loading({
					lock: true,
					text: '正在添加数据请稍后！！！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.form.newsContent = this.form.newsContent.slice(0, 200)
				if (this.info.addOr === 'add') {
					this.$axiosReq(journalism, this.form, null, 'post').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					}).finally(e => {
						loading.close()
					})
				} else {
					this.$axiosReq(journalism + this.row.id, this.form, null, 'put').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					}).finally(e => {
						loading.close()
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>

</style>
