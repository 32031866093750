export function downFile(content, filename) {
  const ele = document.createElement('a');// 创建下载链接
  ele.download = filename;//设置下载的名称
  ele.style.display = 'none';// 隐藏的可下载链接
  // 字符内容转变成blob地址
  const blob = new Blob([content], {type:'application/json,charset=UTF-8'});
  ele.href = URL.createObjectURL(blob);
  // 绑定点击时间
  document.body.appendChild(ele);
  ele.click();
  // 然后移除
  document.body.removeChild(ele);
};

export function downLoadUrl(content,fileName){
  let aEle = document.createElement("a");// 创建a标签
  // blob = new Blob([content]);
  aEle.download = fileName;// 设置下载文件的文件名
  //aEle.href = URL.createObjectUrl(blob);
  aEle.href = content;// content为后台返回的下载地址
  aEle.click();// 设置点击事件

}
