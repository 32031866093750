<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <el-card>
            <el-row type="flex" justify="space-between" :gutter="50">
                <el-col>
                    <span class="text-primary text-pad-right">发布状态：</span>
                    <el-select
                        v-model="condition.releaseStatus"
                        clearable
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition"
                    >
                        <el-option :value="1" label="已发布" />
                        <el-option :value="0" label="未发布" />
                    </el-select>
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">日期</span>
                    <el-date-picker
                        v-model="condition.date"
                        type="daterange"
                        class="input searchInput"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                        size="mini"
                        @change="changeTime"
                    />
                </el-col>
                <el-col>
                    <el-input
                        v-model="condition.search"
                        class="input searchInput"
                        size="mini"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="changeCondition"
                    />
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col>
                                <el-button
                                    v-if="$hasBtn('sys:permissions:news:add')"
                                    type="primary"
                                    size="mini"
                                    @click="
                                        openDialog({
                                            title: '添加新闻',
                                            addOr: 'add',
                                        })
                                    "
                                    >添加新闻</el-button
                                >
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:news:allDelete'
                                        )
                                    "
                                    type="danger"
                                    size="mini"
                                    @click="remove({ del: 'batch' })"
                                    >批量删除</el-button
                                >
                            </el-col>
                            <el-col :span="1">
                                <vxe-button @click="getList">刷新</vxe-button>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="560"
                    row-id="ID"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                >
                    <vxe-table-column type="checkbox" width="60" fixed="left" />
                    <vxe-table-column type="seq" width="60" />
                    <vxe-table-column field="newsTitle" title="新闻标题" show-overflow="title" />
                    <vxe-table-column
                        field="releaseTime"
                        title="发布时间"
                        formatter="toDateString"
                    />
                    <vxe-table-column title="新闻封面">
                        <template v-slot="{ row, rowIndex }">
                            <el-popover placement="right" trigger="hover">
                                <img
                                    :src="$fileUrl + row.newsCoverUrl"
                                    alt=""
                                    style="width: 500px; height: 500px"
                                />
                                <img
                                    slot="reference"
                                    :src="$fileUrl + row.newsCoverUrl"
                                    alt=""
                                    style="width: 100px; height: 100px"
                                />
                            </el-popover>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="内容文件">
                        <template v-slot="{ row, rowIndex }">
                            <span v-if="!row.newsContentUrl">无</span>
                            <el-link
                                v-else
                                :href="$fileUrl + row.newsContentUrl"
                                type="primary"
                                target="_blank"
                                >下载</el-link
                            >
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="release" title="发布状态" />
                    <vxe-table-column
                        v-if="$hasBtn('sys:permissions:news:release')"
                        field="releaseStatus"
                        title="是否发布"
                    >
                        <template v-slot="{ row, rowIndex }">
                            <el-switch
                                ref="switch"
                                v-model="row.releaseStatus"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="1"
                                :inactive-value="0"
                                @change="
                                    (v) => {
                                        changeStatus(v, row);
                                    }
                                "
                            />
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="" title="操作">
                        <template v-slot="{ row, rowIndex }">
                            <el-dropdown size="small" type="primary">
                                <el-button size="small" type="primary">
                                    操作<i
                                        class="el-icon-arrow-down el-icon--right"
                                    />
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-if="
                                            row.releaseStatus === 1 &&
                                            $hasBtn('sys:permissions:news:view')
                                        "
                                        icon="el-icon-edit"
                                        @click.native="
                                            openDialog(
                                                {
                                                    title: '查看新闻',
                                                    addOr: 'view',
                                                },
                                                row
                                            )
                                        "
                                        >查看</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            row.releaseStatus === 0 &&
                                            $hasBtn('sys:permissions:news:edit')
                                        "
                                        icon="el-icon-edit"
                                        @click.native="
                                            openDialog(
                                                {
                                                    title: '修改新闻',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )
                                        "
                                        >修改</el-dropdown-item
                                    >
                                    <!--                  <el-dropdown-item v-if="row.releaseStatus === 0" icon="el-icon-thumb" @click.native="release(row)">发布</el-dropdown-item>-->
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:news:delete'
                                            )
                                        "
                                        :disabled="row.releaseStatus === 1"
                                        icon="el-icon-delete"
                                        @click.native="
                                            remove({ del: 'single' }, row)
                                        "
                                        >删除</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { journalism, journalismRelease } from "@/api";
import Operate from "./Operate";
export default {
    name: "Index",
    components: {
        Operate,
    },
    data() {
        return {
            loading: false,
            condition: {
                releaseStatus: "",
                search: "",
                date: [],
                start: "",
                end: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            company: [],
            loginInfo: {},
        };
    },
    created() {
        this.getList();
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        changeCondition() {
            this.table.currentPage = 1;
            this.getList();
            this.$refs.table.clearCheckboxRow();
        },
        changeTime(v) {
            this.table.currentPage = 1;
            if (v === null) {
                this.condition.start = "";
                this.condition.end = "";
            } else {
                this.condition.start = v[0];
                this.condition.end = v[1];
            }
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                journalism,
                null,
                {
                    startDate: this.condition.start,
                    endDate: this.condition.end,
                    releaseStatus: this.condition.releaseStatus,
                    newsTitle: this.condition.search,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(Number(res.data.totalElements));
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        remove(info, row) {
            const id = [];
            if (info.del === "batch") {
                const arr = this.$refs.table.getCheckboxRecords();
                const arr1 = this.$refs.table.getCheckboxReserveRecords();
                const batchDel = arr.concat(arr1);
                for (const i of batchDel) {
                    id.push(i.id);
                }
                if (id.length === 0) {
                    this.$message.error("请勾选！！！");
                    return;
                }
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(journalism, id, null, "delete")
                            .then((res) => {
                                this.$message.success(res.msg);
                                this.getList(this.condition.company);
                            })
                            .finally((e) => {
                                // id = []
                            });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            } else {
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            journalism + row.id,
                            null,
                            null,
                            "delete"
                        ).then((res) => {
                            this.$message.success(res.msg);
                            this.getList(this.condition.company);
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            }
        },
        changeStatus(v, row) {
            this.$axiosReq(journalismRelease + row.id, null, null, "put").then(
                (res) => {
                    this.$message.success(res.msg);
                    this.getList(this.condition.company);
                }
            );
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
    },
};
</script>

<style scoped></style>
