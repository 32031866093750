<template>
	<div class="editor">
		<div ref="toolbar" class="toolbar" />
		<div ref="editor" class="text" />
	</div>
</template>

<script>
	import { axiosFile } from "@/utils/request";
	import { fileUrl } from "@/api/Global";
	import E from 'wangeditor';
	export default {
		name: 'Index',
		model: {
			prop: 'content',
			event: 'change'
		},
		props: {
			disabled: {
				type: Boolean,
				default: false
			},
			contentBack: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				editor: null,
				info: null
			}
		},
		// 接收父组件的方法
		watch: {
			contentBack: {
				deep: true,
				handler(val) {
					this.editor.txt.html(val)
				}
			}
			// value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
		},
		mounted() {
			this.createEditor()
			this.editor.txt.html(this.content)
		},
		methods: {
			createEditor() {
				this.editor = new E(this.$refs.toolbar, this.$refs.editor)
				const self = this
				// self.editor.config.pasteFilterStyle = false;
				// 配置 server 接口地址
				self.editor.config.menus = ['image']
				// this.editor.config.uploadFileName = 'file'
				// this.editor.config.uploadImgServer = 'http://192.168.88.166:8082/data/server/escort/web/file'
				// this.editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
				// this.editor.config.uploadImgMaxSize = 20 * 1024 * 1024 // 20M
				// this.editor.config.uploadImgHeaders = {
				// 	Authorization: JSON.parse(sessionStorage.UserDetail).token
				// }
				this.editor.config.customUploadImg = function(resultFiles, insertImgFn) {
					const formData = new FormData();
					formData.append("fileType", "RICH_TEXT_FILE");
					formData.append("file", resultFiles[0]);
					axiosFile('/data/server/escort/web/file', formData, null, 'post').then(res => {
						let aaa = fileUrl + res.data.filePath;
						insertImgFn(aaa)
					})
				};
				this.editor.config.onchange = (html) => {
					self.$emit('get-content', {
						content: html,
						noHtml: this.editor.txt.text()
					})
				}
				this.editor.create() // 创建富文本实例
				if (this.disabled) this.editor.$textElem.attr('contenteditable', false)
				else this.editor.$textElem.attr('contenteditable', true)
				this.editor.txt.html(this.contentBack)
			}
		}
	}
</script>

<style scoped>
	.editor {
		width: 100%;
		margin: 0 auto;
		position: relative;
		z-index: 0;
	}

	.toolbar {
		border: 1px solid #ccc;
		background-color: #EAEEF9;
	}

	.text {
		border: 1px solid #ccc;
		min-height: 300px;
		background-color: #EAEEF9;
	}
</style>
